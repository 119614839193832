import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { contractAddress } from '../../config';
import Button from '@material-ui/core/Button';
import { Helmet } from 'react-helmet';

const TITLE = 'processed (art): mirage'

function Home({
  classes,
  allowedMint,
  handleMint,
  setActiveMirage,
  allMirages,
  stateObject,
}) {
  return (
    <div className={classes.paper}>
      <Helmet>
        <title>{ TITLE }</title>
      </Helmet>
      <Grid item xs={12} sm={8} md={6}>
        <a href="https://processed.art">
          <img
            className='logo'
            src='/logo.png'
            width='100%'
            alt='processed (art) logo'
          />
        </a>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography
          className={classes.subtext}
          component='h6'
          variant='overline'
        >
          A LONG-FORM GENERATIVE ART EXPERIMENT
        </Typography>
      </Grid>
      <Typography className={classes.wording} variant='overline'>
        You've stumbled upon a door where your wallet is the key, and curiously
        no currency is required to enter. Inside the entryway is an experimental
        art lab run by humans wielding keyboards, the purveyors welcome you and
        let you know that while there are potential defects abound, enjoyment is
        being had by many in the exploration of imagination and creativity. You
        gaze closer at the wares and realize each provenance piece, unique as
        they be, are being offered to those who inquire for free, as in mints at
        the neighborhood grill. You're then informed that collectors of this
        curio can utilize the outcome in any way they see fit as governed by a{' '}
        <a className={classes.linkHide} target='_blank' href='https://www.niftylicense.org/' rel='noreferrer'>nifty license</a>.
        The only caveat being that participants may only process a single
        composition... What do you do?
      </Typography>
      <Typography className={[classes.wording, classes.definition].join(' ')} variant='overline'>
        mirage:{' '}
        <i>
          a naturally occurring optical phenomenon in which light rays are
          observed to bend via refraction to produce displaced, distorted, or
          multiple images. <br />
          Origin: latin 'mirari', meaning "to look at, to wonder at".
        </i>
      </Typography>
      <Grid container className={classes.pieceDetailsGrid}>
        <Grid item xs={6} sm={6} md={3}>
          <Typography className={classes.pieceDetails} variant='overline'>
            mirage
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Typography className={[classes.pieceDetails, classes.pieceDetailsCount].join(' ')} variant='overline'>
            {stateObject.totalTokens} / {stateObject.totalAllowedTokens}
          </Typography>
        </Grid>
        <Grid item xs={false} sm={false} md={1}></Grid>
        <Grid item xs={12} sm={12} md={5}>
          <form className={classes.form} noValidate>
            <Button
              disabled={allowedMint ? false : true}
              variant='contained'
              color='default'
              fullWidth
              onClick={handleMint}
            >{`sold out`}</Button>
          </form>
        </Grid>
      </Grid>
      <Typography className={classes.wordingTop} variant='overline'>
        Each mirage is created deterministically by code and forever written
        immutably on the Ethereum Blockchain. The pieces are then individually
        generated and stored on the{' '}
        <a className={classes.linkHide} href='https://ipfs.io/' alt='ipfs'>
          InterPlanetary File System
        </a>{' '}
        and distributed throughout the world to ensure high availability. All
        scripts used are stored on the IPFS as well as{' '}
        <a
          className={classes.linkHide}
          href='https://www.arweave.org/'
          alt='ARWerave'
        >
          arweave
        </a>{' '}
        and are referenced in the contract allowing them to be combined with the
        token hash to recreate the processed art for eternity.
        <br />
        <br />
        Mainnet Verified Contract -{' '}
        <a
          className={classes.linkHide}
          target='_blank'
          href={'https://etherscan.io/address/' + contractAddress}
          rel='noreferrer'
        >
          {contractAddress}
        </a>
        <br />
        <a
          className={classes.linkHide}
          target='_blank'
          href='https://opensea.io/collection/processed-art-mirage'
          rel='noreferrer'
        >
          opensea
        </a>
        &nbsp;|&nbsp;
        <a
          className={classes.linkHide}
          target='_blank'
          href='https://discord.gg/8c9TRNqqmx'
          rel='noreferrer'
        >
          discord
        </a>
        &nbsp;|&nbsp;
        <a
          className={classes.linkHide}
          target='_blank'
          href='https://twitter.com/processedart'
          rel='noreferrer'
        >
          twitter
        </a>
        &nbsp;|&nbsp;
        <a
          className={classes.linkHide}
          target='_blank'
          href='https://instagram.com/processed.art'
          rel='noreferrer'
        >
          instagram
        </a>
      </Typography>
      <Typography className={classes.footer} variant='overline'>
        <Link className={classes.linkHide} to='/gallery'>
          gallery
        </Link>
      </Typography>
    </div>
  );
}

export default React.memo(Home);
