import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';

import ArtCard from '../Card/ArtCard';

const TITLE = 'processed (art): mirage | gallery'

function Gallery({ classes, allMirages, setActiveMirage }) {
  return (
    <div className={classes.paperGallery}>
      <Helmet>
        <title>{ TITLE }</title>
      </Helmet>
      <Grid container>
        {allMirages.map((el) => (
          <Grid item xl={4} lg={4} md={4} sm={4} xs={4} style={{ padding: '2%' }} key={el.tokenId}>
            <div className={classes.galleryArt}>
              <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} align='center'>
                  <ArtCard data={el} />
                </Grid>
              </Grid>
            </div>
          </Grid>
        ))}
      </Grid>
      <Typography className={classes.footer} variant='overline'>
        <Link className={classes.linkHide} to='/'>
          home
        </Link>
      </Typography>
    </div>
  );
}

export default React.memo(Gallery);
