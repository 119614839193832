import { Grid, Paper } from '@material-ui/core'
import React from 'react'
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { makeStyles } from '@material-ui/core/styles';
import { ipfsGateway } from "../../config";
import 'react-lazy-load-image-component/src/effects/blur.css';

const useStyles = makeStyles({
    imageElement: {
        width: "100%",
        padding: "0",
        marginBottom: "-6px",
        display: "block"
    }
  });

export default function ArtCard({data}) {
    const classes = useStyles();
    return (
    <Paper elevation={5}>
        <Grid container>
            <Link to={"/mirage/"+ data.tokenId} style={{ display: "block" }}>
                <LazyLoadImage
                alt="processed (art): mirage"
                className={classes.imageElement}
                effect="opacity"
                src={ipfsGateway + '/ipfs/' + data.image.replace('ipfs://','')} />
            </Link>
        </Grid>
    </Paper>
    )
}
