import React, { useState, useCallback, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import '@fontsource/roboto';
import axios from 'axios';
import { serverUrl, ipfsGateway } from './config';

import Home from './components/Home/Home';
import Gallery from './components/Gallery/Gallery';
import Mirage from './components/Mirage/Mirage';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  mirage: {
    width: '50%',
    height: '100%',
    minWidth: '1000px',
    minHeight: '1000px',
    maxWidth: '50%',
    maxHeight: '100%',
    position: 'fixed',
    zIndex: '-99',
  },
  mirageWrapper: {
    minHeight: '800px',
  },
  paper: {
    margin: theme.spacing(6, 1, 1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: '99',
  },
  paperGallery: {
    margin: theme.spacing(6, 6, 1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: '99',
  },
  linkHide: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      color: '#8100ec',
    },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    margin: '5px auto 0',
  },
  subtext: {
    fontSize: '15px',
    margin: '10px 10px',
    textAlign: 'center'
  },
  pieceDetailsGrid: {
    width: '80%',
  },
  pieceDetails: {
    fontSize: '18px',
  },
  pieceDetailsCount: {
    float: 'right',
    marginRight: '20px',
  },
  wording: {
    width: '80%',
    margin: '20px 10px 20px 10px',
  },
  definition: {
    display: 'block',
    lineHeight: '24px',
    margin: '10px 50px 30px',
  },
  wordingTop: {
    width: '80%',
    margin: '40px 10px 20px 10px',
  },
  galleryArt: {
    padding: '10px',
    width: '100%',
  },
  fadeOut: {
    opacity: '0',
    transition: 'opacity 0.2s',
  },
  fadeIn: {
    opacity: '1',
    transition: 'opacity 0.5s 0.5s',
  },
  footer: {
    position: 'fixed',
    bottom: '10px',
    right: '15px',
  },
}));

let contract;
let web3;

function App() {
  const classes = useStyles();
  const [account, setAccount] = useState('');
  const [allowedMint, setAllowedMint] = useState(true);
  const [imgSrc, setImgSrc] = useState('');
  const [mirageVisible, setMirageVisible] = useState(false);
  const [allMirages, setAllMirages] = useState([]);
  const [allManifests, setAllManifests] = useState([]);
  const [stateObject, setStateObject] = useState({});
  const [activeMirage, setActiveMirage] = useState(null);
  
  const handleMint = useCallback(async () => {
    setAllowedMint(false);
    const mint = await contract.methods.mintMirage().send({ from: account });
    const newMirage = await axios.get(
      serverUrl + `mirage/${mint.events.Transfer.returnValues.tokenId}`
    );
    setActiveMirage(newMirage.data);
  }, [account]);

  const setMiragePanel = useCallback(async (activeMirage) => {
    if (activeMirage) {
      setMirageVisible(false);
      setTimeout(function () {
        setImgSrc(`${ipfsGateway}/ipfs/${activeMirage.web_cid}`);
        setMirageVisible(true);
      }, 500);
    }
  }, []);

  const loadManifests = useCallback(async () => {
    let allManifests = await axios.get(serverUrl + 'manifest/all');
    setAllManifests(Object.values(allManifests.data));
  }, []);

  const loadMirages = useCallback(async () => {
    let getAllMirages = await axios.get(serverUrl + 'mirage/all');
    setAllMirages(Object.values(getAllMirages.data));
  }, []);

  const loadBlockChainData = useCallback(async () => {
    let returned = {};
    // if (window.ethereum) {
    //   try {
    //     const accounts = await window.ethereum.request({
    //       method: 'eth_requestAccounts',
    //     });
    //     returned.account = accounts[0];

    //     if (abi && contract) {
    //       let totalMints = await contract.methods.totalMints().call();
    //       let maxMints = await contract.methods.maxMirages().call();
    //       let ownersTokens = await contract.methods.tokensOfOwner(accounts[0]).call();
    //       let mintIsActive = await contract.methods.mintIsActive().call();
          
    //       returned.allowedMint = false;
    //       if (!mintIsActive) {
    //         returned.allowedMint = false;
    //       }
    //       if (totalMints === maxMints) {
    //         returned.allowedMint = false;
    //       }

    //       returned.totalTokens = totalMints;
    //       returned.totalAllowedTokens = maxMints;
    //       //returned.tokensOfOwner = ownersTokens;
    //       console.log(mintIsActive)
    //     } else {
    //       window.alert('No contract abi provided');
    //       returned.allowedMint = false;
    //     }
    //   } catch (error) {
    //     console.log(
    //       '[handleLoadBlockchainData] error.message => ',
    //       error.message
    //     );
    //     returned.allowedMint = false;
    //     returned.mirage = Math.floor(Math.random() * 10).toString();
    //   }
    // } else {
      returned.allowedMint = false;
      returned.mirage = Math.floor(Math.random() * 768).toString();
      returned.totalTokens = 768;
      returned.totalAllowedTokens = 768;
      setStateObject(returned);
  }, []);

  useEffect(() => {
    (async () => {
      await loadMirages();
      await loadBlockChainData();
      await loadManifests();
      setAccount(stateObject.account);
      setAllowedMint(stateObject.allowedMint);
    })();
  }, [
    loadBlockChainData,
    loadMirages,
    stateObject.account,
    stateObject.allowedMint,
  ]);

  useEffect(() => {
    setMiragePanel(activeMirage);
    loadBlockChainData();
  }, [activeMirage, setMiragePanel, loadBlockChainData]);

  useEffect(() => {
    let mirageId = `${Math.ceil(
      Math.random() * (stateObject.totalTokens - 1) + 1
    )}`;

    if (!activeMirage) {
      const mirage = allMirages.find((mirage) => mirage.tokenId === mirageId);
      setActiveMirage(mirage ?? null);
    }
  }, [setActiveMirage, allMirages, stateObject, activeMirage]);

  return (
    <Grid container component='main' className={classes.root}>
      <CssBaseline />
      <Grid className={classes.mirageWrapper} item xs={12} sm={12} md={6}>
        <img
          src={imgSrc}
          className={[
            classes.mirage,
            mirageVisible ? classes.fadeIn : classes.fadeOut,
          ].join(' ')}
          alt='processed (art): mirage'
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} component={Paper} elevation={5} square>
        <Router>
          <Switch>
            <Route path='/gallery'>
              <Gallery
                classes={classes}
                allMirages={allMirages}
                setActiveMirage={setActiveMirage}
              />
            </Route>
            <Route path='/mirage/:mirageId'>
              <Mirage
                classes={classes}
                setActiveMirage={setActiveMirage}
                allMirages={allMirages}
                allManifests={allManifests}
                activeMirage={activeMirage}
              />
            </Route>
            <Route path='/'>
              <Home
                classes={classes}
                allowedMint={allowedMint}
                handleMint={handleMint}
                setActiveMirage={setActiveMirage}
                setAccount={setAccount}
                setAllowedMint={setAllowedMint}
                loadBlockChainData={loadBlockChainData}
                allMirages={allMirages}
                stateObject={stateObject}
              />
            </Route>
          </Switch>
        </Router>
      </Grid>
    </Grid>
  );
}

export default App;
