import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { contractAddress, cloudflareGateway, serverUrl } from '../../config';

const TITLE = 'processed (art): mirage | mirage #${mirageId}'

function Mirage({ classes, setActiveMirage, allMirages, activeMirage, allManifests }) {
  const { mirageId } = useParams();
  const [processedManifest, setprocessedManifest] = useState([]);
  const TITLE = `processed (art): mirage | mirage #${mirageId}`
  useEffect(() => {
    (async () => {
      let mirage = allMirages.find((mirage) => mirage.tokenId === mirageId);
      setActiveMirage(mirage ?? null);
      setprocessedManifest(allManifests.filter(a => a.attributes[1].value == activeMirage.attributes[0].value))
    })();
  }, [setActiveMirage, mirageId, allMirages, activeMirage, processedManifest]);

  return activeMirage ? (
    <div className={classes.paper}>
      <Helmet>
        <title>{ TITLE }</title>
      </Helmet>
      <Grid container style={{ padding: '0 0 0 10%' }}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ padding: '2%', wordWrap: 'break-word' }}        >
          <div className={classes.galleryArt}>
            <Typography
              component='h1'
              variant='overline'
              style={{ fontSize: '32px' }}
            >
              mirage #{activeMirage.tokenId}
            </Typography>
            <Typography
              component='h6'
              variant='overline'
              style={{ fontSize: '16px' }}
            >
              background color
            </Typography>
            <Typography component='h6' variant='overline'>
              {activeMirage.attributes[1].value}
            </Typography>
            <Typography
              component='h6'
              variant='overline'
              style={{ fontSize: '16px' }}
            >
              overlay color
            </Typography>
            <Typography component='h6' variant='overline'>
              {activeMirage.attributes[2].value}
            </Typography>
            <Typography
              component='h6'
              variant='overline'
              style={{ fontSize: '16px' }}
            >
              series
            </Typography>
            <Typography component='h6' variant='overline'>
              {activeMirage.attributes[4].value}
            </Typography>
            <Typography
              component='h6'
              variant='overline'
              style={{ fontSize: '16px' }}
            >
              birthday
            </Typography>
            <Typography component='h6' variant='overline'>
              {new Date(activeMirage.attributes[3].value).toLocaleString()}
            </Typography>
            <Typography
              component='h6'
              variant='overline'
              style={{ fontSize: '16px' }}
            >
              license
            </Typography>
            <Typography component='h6' variant='overline'>
              <a
                className={classes.linkHide}
                href='https://www.niftylicense.org/'
              >
                {activeMirage.license}
              </a>
            </Typography>
            <Typography
              component='h6'
              variant='overline'
              style={{ fontSize: '16px' }}
            >
              Has Manifested
            </Typography>
            <Typography component='h6' variant='overline'>
            <a
                className={classes.linkHide}
                target='_blank'
                href={processedManifest[0] ? `${processedManifest[0].external_url}` : `https://opensea.io/assets/${contractAddress}/${activeMirage.tokenId}`}
                rel='noreferrer'
              >
                {processedManifest[0] ? 'true' : 'false'}
            </a>
            </Typography>
            <Typography
              component='h6'
              variant='overline'
              style={{ fontSize: '16px' }}
            >
              token hash
            </Typography>
            <Typography component='h6' variant='overline'>
              <a
                className={classes.linkHide}
                target='_blank'
                href={`https://etherscan.io/token/${contractAddress}?a=${activeMirage.tokenId}`}
                rel='noreferrer'
              >
                {activeMirage.attributes[0].value}
              </a>
            </Typography>
            <Typography
              component='h6'
              variant='overline'
              style={{ fontSize: '16px' }}
            >
              links
            </Typography>
            <Typography component='h6' variant='overline'>
              <a
                className={classes.linkHide}
                target='_blank'
                href={`https://opensea.io/assets/${contractAddress}/${activeMirage.tokenId}`}
                rel='noreferrer'
              >
                opensea
              </a>
              &nbsp; | &nbsp;
              <a
                className={classes.linkHide}
                target='_blank'
                href={`${cloudflareGateway}/ipfs/${activeMirage.cid}`}
                rel='noreferrer'
              >
                high resolution image
              </a>
              &nbsp; | &nbsp;
              <a
                className={classes.linkHide}
                target='_blank'
                href={`${serverUrl}generate/mirage/${activeMirage.tokenId}`}
                rel='noreferrer'
              >
                script
              </a>
              &nbsp; | &nbsp;
              <a
                className={classes.linkHide}
                target='_blank'
                href={`${activeMirage.interactive_nft.ipfs_uri}`}
                rel='noreferrer'
              >
                IPFS
              </a>
              &nbsp; | &nbsp;
              <a
                className={classes.linkHide}
                target='_blank'
                href={`${activeMirage.interactive_nft.arweave_uri}`}
                rel='noreferrer'
              >
                arweave
              </a>
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Typography className={classes.footer} variant='overline'>
        <Link className={classes.linkHide} to='/'>
          home
        </Link>
        &nbsp; | &nbsp;
        <Link className={classes.linkHide} to='/gallery'>
          gallery
        </Link>
      </Typography>
    </div>
  ) : null;
}

export default React.memo(Mirage);
